import "@retailtune/vanilla-jsx";
import {
  api,
  positionToLatLngLiteral,
  fetchUserDefaultPosition,
  fetchUserPosition,
  createDebounceFn,
  createAutocompleteHandler,
  retailTuneAutocompleteHandler,
  setExpirable,
  getExpirable,
  USER_POSITION_CONSENT,
  getDevice,
  createPosition,
  sortStoresByPriority,
  getRadialDistanceFn,
  createExpirationValue,
} from "@retailtune/utils";
import {
  createToastMessagesContainer,
  ARIA_Autocomplete,
  createSidebar,
  createScrollButton,
} from "@retailtune/vanilla-ui-core";
import { Service, Store } from "@retailtune/types/lib/store";
import { Position } from "@retailtune/types/lib/geolocation";
import {
  AnalyticsCategory,
  AnalyticsAction,
} from "@retailtune/types/lib/analytics";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import { Expirable } from "@retailtune/types/lib/local-storage";
import {
  googleAutocompleteHandler,
  CustomRenderer,
} from "@retailtune/google-maps-utils";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Translations } from "../common/translations";
import { Trap_focus } from "../common/utils";
import { createOpeningHoursText } from "../common/openingHours";
// TODO
import { mapsStyles } from "../common/mapStyles";

// ______________ TYPE DEFINITIONS ______________

declare const retailtune: {
  urlPrefix: string;
  api_key: string;
  language: string;
  translations: Translations;
};

// Analytics
declare function sendGa4DL(
  category: AnalyticsCategory,
  action: AnalyticsAction,
  label: string,
  storeId: number
): void;

interface Positions {
  current: Position;
  user: Position;
  default: Position;
}

interface Directions {
  origin: google.maps.LatLngLiteral;
  destination: {
    storeName: string;
    link: string;
    latlngLitteral: google.maps.LatLngLiteral;
    storeType: {
      id: string;
      name: string;
    };
  } | null;
  travelMode: google.maps.TravelMode;
}

interface Filterobj {
  name: string;
  filterParam: string;
  icon: string;
  filterGroup: string;
}

interface Filterstate {
  [key: string]: Filterobj[];
}

// ______________ GLOBAL VARIABLES ______________

let appliedFilters: Filterobj[] = [];
let filterObject: Filterstate = {};
let deviceType = getDevice();

let storesArray: Store[];
let filteredStores: Store[] = [];
let visibleStores: Store[];
let servicesArray: Service[];

let storeCountriesSet = new Set<string>();
let storeTypeIdSet = new Set<string>();
let storeTypeNameSet = new Set<string>();
let storeIdToMarkerMap = new Map<number, google.maps.Marker>();
let directionsPaneTrapFocusObj: Trap_focus;
let originAutocompleteObj: ARIA_Autocomplete<PredictionData>;
let mainAutocompleteObj: ARIA_Autocomplete<PredictionData>;

let positions: Positions;
let directions: Directions;
let travelModes: google.maps.TravelMode[];

let map: google.maps.Map;
let markersArray: google.maps.Marker[];
let infoWindow: google.maps.InfoWindow;
let directionsRendererObj: google.maps.DirectionsRenderer;
let googleDirectionsService: google.maps.DirectionsService;
let markerClustererObj: MarkerClusterer;
let destinationMarker: google.maps.Marker;
let userMarker: google.maps.Marker;

let toggleSidebar: (shouldShow: boolean) => void;
let updateSideBarContent: (newContent: HTMLElement) => void;
let toast: (message: string) => void;

async function main() {
  const { api_key, language, urlPrefix, translations } = retailtune;
  api.setOrigin({ type: process.env.NODE_ENV as any });

  // ______________ APPLICATION SET-UPS ______________
  const [pageData, _defaultPosition] = await Promise.all([
    api.fetchPageData(api_key, language),
    fetchUserDefaultPosition(api_key),
  ]);

  storesArray = pageData.stores;
  filteredStores = pageData.stores;
  servicesArray = pageData.services;

  positions = {
    default: _defaultPosition,
    user: _defaultPosition,
    current: _defaultPosition,
  };

  const mapContainer = document.getElementById("rt_map")!;

  map = new google.maps.Map(mapContainer, {
    center: positionToLatLngLiteral(positions.user),
    zoom: 10,
    styles: mapsStyles,
  });

  const mapDebounceFunc = createDebounceFn()(200);
  map.addListener("bounds_changed", () => {
    mapDebounceFunc(() => {
      createStoreCards();
    });
  });

  infoWindow = new google.maps.InfoWindow();

  for (let store of storesArray) {
    if (store.status === "closed") {
      continue;
    }

    const storePosition = {
      lat: store.latitude,
      lng: store.longitude,
    };

    const marker = new google.maps.Marker({
      position: storePosition,
      icon: `${urlPrefix}/img/pin/pin-store-${store.storeTypes[0].id}.svg`,
    });

    marker.addListener("click", () => {
      infoWindow.setContent(getInfoWindow(store));
      infoWindow.open({
        anchor: marker,
        map,
      });

      // Analytics
      sendGa4DL(
        "Store",
        "Click",
        `StoreClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
        store.id
      );
    });

    storeCountriesSet.add(store.country.alpha2);
    storeTypeNameSet.add(store.storeTypes[0].name);
    storeTypeIdSet.add(store.storeTypes[0].id);
    storeIdToMarkerMap.set(store.id, marker);
  }
  markersArray = Array.from(storeIdToMarkerMap.values());

  // objects construction for filter sidebar creation
  const storeTypeNameArray = Array.from(storeTypeNameSet);
  filterObject[`${translations.k_tipology}`] = Array.from(storeTypeIdSet).map(
    (st, index) => {
      return {
        filterParam: st,
        icon: "",
        // TODO Change when storeTypes[0].name is not an empty string
        // name: storeTypeNameArray[index],
        name: st,
        filterGroup: "tipology",
      };
    }
  );

  servicesArray.forEach((service) => {
    if (filterObject[service.groupName]) {
      filterObject[service.groupName].push({
        name: service.name,
        icon: service.icon,
        filterParam: service.id.toString(),
        filterGroup: service.groupName,
      });
    } else {
      filterObject[service.groupName] = [
        {
          name: service.name,
          icon: service.icon,
          filterParam: service.id.toString(),
          filterGroup: service.groupName,
        },
      ];
    }
  });

  markerClustererObj = new MarkerClusterer({
    map,
    markers: markersArray,
    renderer: new CustomRenderer({
      clusterIcon: `${urlPrefix}/img/pin/cluster.svg`,
      clusterLabelColor: "#006241",
    }),
  });

  userMarker = new google.maps.Marker({
    map,
    position: positionToLatLngLiteral(positions.current),
    icon: `${urlPrefix}/img/pin/pin-user.svg`,
  });

  googleDirectionsService = new google.maps.DirectionsService();
  directionsRendererObj = new google.maps.DirectionsRenderer({
    polylineOptions: {
      strokeColor: "#006241",
    },
    markerOptions: {
      visible: false,
    },
  });

  travelModes = [
    google.maps.TravelMode.WALKING,
    google.maps.TravelMode.DRIVING,
    google.maps.TravelMode.TRANSIT,
  ];

  directions = {
    origin: positionToLatLngLiteral(positions.user),
    travelMode: google.maps.TravelMode.DRIVING,
    destination: null,
  };

  const travelModesContainer = document.getElementById("rt_travel_modes")!;
  travelModesContainer.replaceChildren(
    <>
      {travelModes.map((travelMode) => {
        const travelModeBtnEl: HTMLElement = (
          <button
            id={`rt_travel_mode_${travelMode.toLowerCase()}_btn`}
            aria-label={travelMode.toLowerCase()}
            class={`rt-travel-mode ${
              directions.travelMode === travelMode
                ? "rt-travel-mode--selected"
                : ""
            }`}
            onclick={async (e: Event) => {
              const oldTravelMode = directions.travelMode;
              directions.travelMode = travelMode;
              const currentTravelModeBtn = e.currentTarget as HTMLButtonElement;
              for (const travelModeBtn of travelModesContainer.children) {
                travelModeBtn.classList.remove("rt-travel-mode--selected");
              }
              currentTravelModeBtn.classList.add("rt-travel-mode--selected");

              const ssuccessResult = await navigateUser();
              if (!ssuccessResult) {
                directions.travelMode = oldTravelMode;
                const oldTravelModeBtn = document.getElementById(
                  `rt_travel_mode_${oldTravelMode.toLowerCase()}_btn`
                )!;
                for (const travelModeBtn of travelModesContainer.children) {
                  travelModeBtn.classList.remove("rt-travel-mode--selected");
                }
                oldTravelModeBtn.classList.add("rt-travel-mode--selected");
              }
            }}
          >
            <img
              class="rt-travel-mode__icon"
              src={`${urlPrefix}/img/icon/${travelMode.toLowerCase()}-inactive.svg`}
              alt="travel mode icon"
            />
            <img
              class="rt-travel-mode__icon"
              src={`${urlPrefix}/img/icon/${travelMode.toLowerCase()}-active.svg`}
              alt="travel mode icon"
            />
          </button>
        );
        travelModeBtnEl.setAttribute("travelMode", travelMode);
        return travelModeBtnEl;
      })}
    </>
  );

  // ______________ LoCaL cOmPoNeNt ______________

  mainAutocompleteObj = new ARIA_Autocomplete({
    anchor: "rt_main_autocomplete",
    handlers: {
      predictionsSearch: createAutocompleteHandler(
        retailTuneAutocompleteHandler(api_key, {
          countries: Array.from(storeCountriesSet),
        }),
        googleAutocompleteHandler()
      ),
      predictionsSelection: (perdictionData: PredictionData) => {
        if (directions.destination) {
          clearDrivingDirections();
        }

        positions.current = createPosition({
          latitude: perdictionData.latitude,
          longitude: perdictionData.longitude,
        });

        const predictionLatLngLitteral = {
          lat: perdictionData.latitude,
          lng: perdictionData.longitude,
        };

        userMarker.setPosition(predictionLatLngLitteral);
        map.setCenter(predictionLatLngLitteral);

        // Analytics
        sendGa4DL("StoreLocator", "Click", "FreeSearch", 0);
      },
    },
    id: "main",
    translations: {
      inputLabel: translations.k_search,
      inputPlaceholder: translations.k_autocomplete_placeholder,
      zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    },
  });

  originAutocompleteObj = new ARIA_Autocomplete({
    anchor: "rt_autocomplete_origin",
    handlers: {
      predictionsSearch: createAutocompleteHandler(
        retailTuneAutocompleteHandler(api_key, {
          countries: Array.from(storeCountriesSet),
        }),
        googleAutocompleteHandler()
      ),
      predictionsSelection: (perdictionData: PredictionData) => {
        directions.origin = {
          lat: perdictionData.latitude,
          lng: perdictionData.longitude,
        };
        navigateUser();

        // Analytics
        sendGa4DL("StoreLocator", "Click", "FreeSearchDirections", 0);
      },
    },
    id: "origin",
    translations: {
      inputLabel: translations.k_search,
      inputPlaceholder: translations.k_autocomplete_placeholder,
      zeroResultsMessage: translations.k_autocomplete_zero_results_message,
    },
  });

  [toast] = createToastMessagesContainer({
    anchor: "rt_homepage",
    position: "top-right",
  });

  [toggleSidebar, updateSideBarContent] = createSidebar({
    position: "right",
    anchor: "rt_homepage",
    content: getSideBarContent(),
  });

  const directionsPaneContainer =
    document.getElementById("rt_directions_pane")!;
  directionsPaneTrapFocusObj = new Trap_focus(
    directionsPaneContainer,
    "rt_skip_link"
  );

  // ______________ DOM MODIFCATIONS ______________

  // store list SCT button
  createScrollButton({
    anchorEl: "rt_list_container",
    scrollingEl: "rt_list_container",
    id: "rt_store_list_scroller",
  });

  // directions pane SCT button
  createScrollButton({
    anchorEl: "rt_directions_pane",
    scrollingEl: "rt_directions_pane",
    id: "rt_directions_pane_scroller",
  });

  const filterBtn = document.getElementById("rt_btn_filters")!;
  filterBtn.onclick = () => {
    toggleSidebar(true);
  };

  const directionsPaneCloseBtn = document.getElementById(
    "rt_directions_pane_btn_close"
  )!;
  directionsPaneCloseBtn.onclick = () => {
    clearDrivingDirections();

    setTimeout(() => directionsPaneTrapFocusObj.untrap(), 100);
  };

  const backToNearestStoreBtn = document.getElementById(
    "rt_back_to_nearest_store"
  )!;
  backToNearestStoreBtn.onclick = (e: Event) => {
    if (filteredStores.length === 0) {
      return map.setZoom(1);
    }
    do {
      let currentZoom = map.getZoom()!;
      map.setZoom(--currentZoom);
      createStoreCards();
    } while (visibleStores.length === 0);
  };

  const findClosestStore = document.getElementById("rt_find_closest_store")!;
  findClosestStore.onclick = async () => {
    clearDrivingDirections();
    geolocateUser();
    // Analytics
    sendGa4DL("StoreLocator", "Click", "FindNearestStore", 0);
  };

  const positionConsentModal = document.getElementById(
    "rt_position_consent_modal"
  ) as HTMLDialogElement;

  const consentModalCloseBtn = document.getElementById(
    "rt_consent_modal_close_btn"
  )!;
  consentModalCloseBtn.onclick = () => {
    positionConsentModal.close();
  };

  const positionConsentModalRejectionBtn = document.getElementById(
    "rt_position_consent_modal_rejection"
  )!;
  positionConsentModalRejectionBtn.onclick = () => {
    positionConsentModal.close();

    // Analytics
    sendGa4DL("StoreLocator", "Geo", "Disagree", 0);
  };

  const positionConsentModalAgreementBtn = document.getElementById(
    "rt_position_consent_modal_agreement"
  )!;
  positionConsentModalAgreementBtn.onclick = () => {
    setExpirable(USER_POSITION_CONSENT, {
      value: "true",
      expiration: createExpirationValue(1, "years"),
    });
    geolocateUser();

    // Analytics
    sendGa4DL("StoreLocator", "Geo", "Agree", 0);
  };

  window.onresize = () => {
    const curentDeviceType = getDevice();
    // condition refers to when directions service is active and device is switched from desktop to mobile/tablet.
    if (
      directions.destination &&
      deviceType === "desktop" &&
      curentDeviceType !== "desktop"
    ) {
      deviceType = getDevice();
      // reinitialize directions service
      initDrivingDirections();
      clearDrivingDirections();
    }
  };

  await geolocateUser();

  const destinationStoreCode = new URLSearchParams(window.location.search).get(
    "code"
  );
  const destinationStore = storesArray.find(
    (store) => store.storeCode === destinationStoreCode
  );
  if (destinationStore) {
    const storePosition = {
      lat: destinationStore.latitude,
      lng: destinationStore.longitude,
    };
    directions.destination = {
      latlngLitteral: storePosition,
      link: destinationStore.googleMapsLink,
      storeName: destinationStore.name,
      storeType: {
        id: destinationStore.storeTypes[0].id,
        name: destinationStore.storeTypes[0].name,
      },
    };
    initDrivingDirections();
  }
}

async function geolocateUser() {
  if (getExpirable<Expirable>(USER_POSITION_CONSENT)) {
    positions.user = await fetchUserPosition(positions.default);

    switch (positions.user.type) {
      case "html5":
        // Analytics
        positions.user.origin === "fetched"
          ? sendGa4DL("StoreLocator", "Geo", "Success", 0)
          : sendGa4DL("StoreLocator", "Geo", "SuccessCookies", 0);
        break;
      case "ip":
        toast(retailtune.translations.k_geolocation_toast_message);

        // Analytics
        positions.user.origin === "fetched"
          ? sendGa4DL("StoreLocator", "GeoIP", "Success", 0)
          : sendGa4DL("StoreLocator", "GeoIP", "SuccessCookies", 0);
        break;
      case "default":
        toast(retailtune.translations.k_geolocation_toast_message);

        // Analytics
        sendGa4DL("StoreLocator", "GeoDefault", "Success", 0);
        break;
    }

    const userPosition = positionToLatLngLiteral(positions.user);
    directions.origin = userPosition;

    map.setZoom(12);
    map.setCenter(userPosition);
    userMarker.setPosition(userPosition);
    // show modal
    const positionConsentModal = document.getElementById(
      "rt_position_consent_modal"
    ) as HTMLDialogElement;
    positionConsentModal.close();
  } else {
    // show modal
    const positionConsentModal = document.getElementById(
      "rt_position_consent_modal"
    ) as HTMLDialogElement;
    positionConsentModal.showModal();
  }
}

function initDrivingDirections() {
  if (getDevice() !== "desktop") {
    window.open(directions.destination!.link, "_blank");
    return;
  }

  // opening directions pane
  const directionsPaneContainer =
    document.getElementById("rt_directions_pane")!;
  directionsPaneContainer.classList.add("rt-directions-pane--visible");

  const travelModesContainer = document.getElementById("rt_travel_modes")!;
  for (const travelModeBtn of travelModesContainer.children) {
    travelModeBtn.classList.remove("rt-travel-mode--selected");
    if (travelModeBtn.getAttribute("travelMode") === directions.travelMode) {
      travelModeBtn.classList.add("rt-travel-mode--selected");
    }
  }

  // switch scroll to top component
  const storeListScroller = document.getElementById("rt_store_list_scroller")!;
  storeListScroller.classList.remove("rt-back-to-top--visible");

  // destination label change
  const instructionsLableContainer = document.getElementById(
    "rt_instructions_label"
  )!;
  instructionsLableContainer.replaceChildren(
    <>
      <img
        aria-hidden="true"
        src={`${retailtune.urlPrefix}/img/pin/pin-store-${directions.destination?.storeType.id}.svg`}
        alt="destination icon"
        width="30"
        height="36"
      />
      <span>{directions.destination!.storeName}</span>
    </>
  );

  // address destination label & pin change
  const addressDestinationSpan = document.getElementById(
    "rt_address_destination"
  )!;
  addressDestinationSpan.replaceChildren(
    <>
      <img
        aria-hidden="true"
        src={`${retailtune.urlPrefix}/img/pin/pin-store-${
          directions.destination!.storeType.id
        }.svg`}
        alt="destination pin"
        width="20"
        height="24"
      />
      <span>{directions.destination!.storeName}</span>
    </>
  );

  // trapping focus inside directions pane
  setTimeout(() => directionsPaneTrapFocusObj.trap(), 100);

  destinationMarker = new google.maps.Marker({
    position: directions.destination
      ? directions.destination.latlngLitteral
      : null,
    icon: `${retailtune.urlPrefix}/img/pin/pin-store-${directions.destination?.storeType.id}.svg`,
  });

  markerClustererObj.clearMarkers();
  markerClustererObj.addMarker(destinationMarker);

  navigateUser();
}

async function navigateUser(): Promise<boolean | void> {
  if (!directions.destination) {
    return;
  }

  let navigationResultSuccess = true;

  userMarker.setPosition(directions.origin);

  // initialize google maps directions API
  try {
    const directionsResultObj = await googleDirectionsService.route({
      origin: directions.origin,
      destination: directions.destination.latlngLitteral,
      travelMode: directions.travelMode,
      language: retailtune.language,
    });

    const instructionsList = document.getElementById("rt_instructions_list")!;
    instructionsList.replaceChildren(
      <>
        {directionsResultObj.routes[0].legs[0].steps.map((step, index) => (
          <li tabIndex="0" class="rt-instructions-item">
            <div>
              <strong>{++index}.</strong>
              <span innerHTML={step.instructions}></span>
            </div>
          </li>
        ))}
      </>
    );

    directionsRendererObj.setOptions({
      directions: directionsResultObj,
      map,
    });
  } catch (e) {
    toast(retailtune.translations.k_navigation_toast_message);
    navigationResultSuccess = false;
  }
  return navigationResultSuccess;
}

function getInfoWindow(store: Store): HTMLElement {
  const { language, urlPrefix, translations } = retailtune;
  return (
    <article class="rt-iw">
      {store.status === "will_open_soon" && (
        <span class="rt-iw__next-opening | rt-next-opening">
          {translations.k_opening_soon}
        </span>
      )}
      <span class="rt-iw__name  ">{store.name}</span>
      <div class="rt-iw__info">
        <span class="rt-iw__address">
          {store.address1},{store.postalCode}, {store.city},{" "}
          {store.province.code}
        </span>
        {store.phone && (
          <ul class="rt-iw__contacts">
            <li>
              <a
                href={`tel:${store.phone}`}
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `PhoneClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                <img
                  aria-hidden="true"
                  src={`${urlPrefix}/img/icon/phone.svg`}
                  alt="phone icon"
                  width="9"
                  height="15"
                />
                <span>{store.phone}</span>
              </a>
            </li>
          </ul>
        )}
      </div>
      <div class="rt-iw__cta">
        <a
          class="rt-iw__details | rt-btn rt-btn--primary"
          href={urlPrefix + "/" + language + store.storeLink.path}
        >
          {translations.k_info_and_promotions}
        </a>
        <button
          class="rt-iw__directions | rt-btn rt-btn--secondary"
          onclick={() => {
            const storePosition = {
              lat: store.latitude,
              lng: store.longitude,
            };
            directions.destination = {
              latlngLitteral: storePosition,
              link: store.googleMapsLink,
              storeName: store.name,
              storeType: {
                id: store.storeTypes[0].id,
                name: store.storeTypes[0].name,
              },
            };
            initDrivingDirections();

            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `DirectionsClickMap-${store.city} ${store.address1}-${deviceType}-${language}`,
              store.id
            );
          }}
        >
          {translations.k_bring_me_here}
        </button>
      </div>
    </article>
  );
}

function filterStores() {
  if (appliedFilters.length === 0) {
    return resetFilter();
  }

  if (directions.destination) {
    clearDrivingDirections();
  }

  filteredStores = [];
  markersArray = [];
  markerClustererObj.clearMarkers();

  let serviceIsSelected = false;
  let tipologyIsSelected = false;
  let tipologyAndServiceAreSelected = false;
  appliedFilters.forEach((af) => {
    if (af.filterGroup === "tipology") {
      tipologyIsSelected = true;
    } else {
      serviceIsSelected = true;
    }
  });

  if (serviceIsSelected && tipologyIsSelected) {
    tipologyAndServiceAreSelected = true;
  }

  if (tipologyAndServiceAreSelected) {
    storesArray.forEach((store) => {
      if (
        appliedFilters.some(
          (af) => af.filterParam === store.storeTypes[0].id
        ) &&
        appliedFilters.some((af) =>
          store.serviceIds.some((si) => +af.filterParam === si)
        )
      ) {
        filteredStores.push(store);
        markersArray.push(storeIdToMarkerMap.get(store.id)!);
      }
    });
  } else {
    storesArray.forEach((store) => {
      if (tipologyIsSelected) {
        if (
          appliedFilters.some((af) => af.filterParam === store.storeTypes[0].id)
        ) {
          filteredStores.push(store);
          markersArray.push(storeIdToMarkerMap.get(store.id)!);
        }
      } else {
        if (
          appliedFilters.some((af) =>
            store.serviceIds.some((si) => +af.filterParam === si)
          )
        ) {
          filteredStores.push(store);
          markersArray.push(storeIdToMarkerMap.get(store.id)!);
        }
      }
    });
  }
  markerClustererObj.addMarkers(markersArray);
  createStoreCards();
}

function resetFilter() {
  appliedFilters = [];
  filteredStores = storesArray;
  markersArray = Array.from(storeIdToMarkerMap.values());
  markerClustererObj.addMarkers(markersArray);
  createStoreCards();
}

function createStoreCards() {
  const { language, translations, urlPrefix } = retailtune;
  visibleStores = [];
  const mapBounds = map.getBounds()!;

  for (let i = 0; i < filteredStores.length; ++i) {
    if (filteredStores[i].status === "closed") {
      continue;
    }

    const storePosition = {
      lat: filteredStores[i].latitude,
      lng: filteredStores[i].longitude,
    };

    const getDistance = getRadialDistanceFn(
      positions.current.latitude,
      positions.current.longitude
    );

    if (mapBounds.contains(storePosition)) {
      filteredStores[i].distance = getDistance(
        filteredStores[i].latitude,
        filteredStores[i].longitude
      );
      visibleStores.push(filteredStores[i]);
    }
  }

  visibleStores.sort(sortStoresByPriority);

  const backToNearestStoreBtn = document.getElementById(
    "rt_back_to_nearest_store"
  )!;
  if (visibleStores.length === 0) {
    backToNearestStoreBtn.classList.add("rt-back-to-nearest-store--visible");
  } else {
    backToNearestStoreBtn.classList.remove("rt-back-to-nearest-store--visible");
  }

  const storeCardsList = document.getElementById("rt_store_list")!;
  storeCardsList.replaceChildren(
    <>
      {visibleStores.map((store) => (
        <li
          onclick={() => {
            const storePosition = {
              lat: store.latitude,
              lng: store.longitude,
            };

            infoWindow.setContent(getInfoWindow(store));
            infoWindow.setPosition(storePosition);
            infoWindow.open({
              map,
              anchor: storeIdToMarkerMap.get(store.id),
            });

            // Analytics
            sendGa4DL(
              "Store",
              "Click",
              `StoreClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
              store.id
            );
          }}
        >
          <article tabindex="0" class="rt-store-card">
            {store.status === "will_open_soon" && (
              <span class="rt-store-card__next-opening | rt-next-opening">
                {translations.k_opening_soon}
              </span>
            )}
            <div class="rt-store-card__heading">
              <span class="rt-store-card__name">{store.name}</span>
            </div>
            <div class="rt-store-card__info">
              <span class="rt-store-card__address">
                {store.address1} {store.city} {store.postalCode}
              </span>
              {store.phone && (
                <ul class="rt-store-card__contacts">
                  <li>
                    <a
                      href={`tel:${store.phone}`}
                      onclick={(e: Event) => {
                        e.stopPropagation();
                        // Analytics
                        sendGa4DL(
                          "Store",
                          "Click",
                          `PhoneClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                          store.id
                        );
                      }}
                    >
                      <img
                        aria-hidden="true"
                        src={`${urlPrefix}/img/icon/phone.svg`}
                        alt="phone icon"
                        width="9"
                        height="15"
                      />
                      <span>{store.phone}</span>
                    </a>
                  </li>
                </ul>
              )}
              <span class="rt-store-card__hours-today">
                {createOpeningHoursText(language, store, translations)}
              </span>
            </div>
            <div class="rt-store-card__cta">
              <a
                class="rt-store-card__details | rt-btn rt-btn--primary"
                href={urlPrefix + "/" + language + store.storeLink.path}
                onclick={(e: Event) => {
                  e.stopPropagation();
                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `DetailsClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                {translations.k_info_and_promotions}
              </a>
              <button
                class="rt-store-card__directions | rt-btn rt-btn--secondary"
                onclick={(e: Event) => {
                  e.stopPropagation();
                  const storePosition = {
                    lat: store.latitude,
                    lng: store.longitude,
                  };
                  directions.destination = {
                    latlngLitteral: storePosition,
                    link: store.googleMapsLink,
                    storeName: store.name,
                    storeType: {
                      id: store.storeTypes[0].id,
                      name: store.storeTypes[0].name,
                    },
                  };
                  initDrivingDirections();

                  // Analytics
                  sendGa4DL(
                    "Store",
                    "Click",
                    `DirectionsClickListing-${store.city} ${store.address1}-${deviceType}-${language}`,
                    store.id
                  );
                }}
              >
                {translations.k_bring_me_here}
              </button>
            </div>
          </article>
        </li>
      ))}
    </>
  );
}

function clearDrivingDirections() {
  const userPositionLatLngLitteral = positionToLatLngLiteral(positions.user);

  directions = {
    origin: userPositionLatLngLitteral,
    destination: null,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  // switching back scroll to top component
  const directionsPaneScroller = document.getElementById(
    "rt_directions_pane_scroller"
  )!;
  directionsPaneScroller.classList.remove("rt-back-to-top--visible");

  const directionsPaneContainer =
    document.getElementById("rt_directions_pane")!;
  directionsPaneContainer.classList.remove("rt-directions-pane--visible");

  originAutocompleteObj.resetAutocomplete();
  mainAutocompleteObj.resetAutocomplete();

  directionsRendererObj.setMap(null);
  markersArray = Array.from(storeIdToMarkerMap.values());
  markerClustererObj.addMarkers(markersArray);
}

function getSideBarContent(): HTMLElement {
  const { translations } = retailtune;
  return (
    <div class="rt-filters">
      <header class="rt-filters__header">
        <h2 class="rt-filters__heading">{translations.k_filter}</h2>
        <button
          onclick={() => {
            toggleSidebar(false);
            resetFilter();
            updateSideBarContent(getSideBarContent());
          }}
          class="rt-filters__reset"
        >
          {translations.k_cancel_filters}
        </button>
        <button
          onclick={() => {
            toggleSidebar(false);
          }}
          class="rt-btn-close"
        >
          <img
            aria-hidden="true"
            src={`${retailtune.urlPrefix}/img/icon/cross.svg`}
            alt="close icon"
            width="16"
            height="16"
          />
        </button>
      </header>
      <div id="rt_filters_body_container" class="rt-filters__body">
        {appliedFilters.length > 0 && (
          <div
            id="rt_applied_filters_container"
            class="rt-filters__applied-filters"
          >
            <span
              class="rt-filters__title"
              data-selected-options={appliedFilters.length}
            >
              {translations.k_applied_filters}
            </span>
            <ul id="rt_applied_filters_list">
              {appliedFilters.map((appliedFilter) => (
                <li>
                  <span class="rt-filters__applied-filter">
                    {appliedFilter.name}
                    <button
                      onclick={(e: Event) => {
                        e.stopPropagation();
                        appliedFilters = appliedFilters.filter(
                          (filter) =>
                            filter.filterParam !== appliedFilter.filterParam
                        );
                        filterStores();
                        updateSideBarContent(getSideBarContent());
                      }}
                      class="rt-btn-close"
                    >
                      <img
                        aria-hidden="true"
                        src={`${retailtune.urlPrefix}/img/icon/cross.svg`}
                        alt="close icon"
                        width="10"
                        height="10"
                      />
                    </button>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <ul id="rt_filters_categories_list" class="rt-filters__categories">
          {Object.keys(filterObject).map((filterGroup, accordionIndex) => (
            <li class="rt-filters__category">
              <div class="rt-accordion">
                <button
                  type="button"
                  aria-expanded={appliedFilters.some(
                    (af) => af.filterGroup === filterGroup
                  )}
                  class="rt-accordion-trigger"
                  aria-controls={`rt_accordion_panel_filters_${++accordionIndex}`}
                  id={`rt_accordion_trigger_filters_${accordionIndex}`}
                  onclick={(e: Event) => {
                    e.stopPropagation();
                    const currentEl = e.currentTarget as HTMLButtonElement;
                    currentEl.ariaExpanded =
                      currentEl.ariaExpanded === "true" ? "false" : "true";
                    const accordionPanel =
                      currentEl.nextSibling as HTMLDivElement;
                    accordionPanel.toggleAttribute("hidden");
                  }}
                >
                  <span class="rt-accordion-title">
                    <span
                      class="rt-filters__title"
                      data-selected-options={
                        appliedFilters.filter(
                          (af) => af.filterGroup === filterGroup
                        ).length
                      }
                    >
                      {filterGroup}
                    </span>
                    <img
                      class="rt-accordion-icon"
                      src={`${retailtune.urlPrefix}/img/icon/chevron.svg`}
                      alt="chevron icon"
                      aria-hidden="true"
                    />
                  </span>
                </button>
                <div
                  id={`rt_accordion_panel_filters_${accordionIndex}`}
                  role="region"
                  aria-labelledby={`rt_accordion_trigger_filters_${accordionIndex}`}
                  class="rt-accordion-panel"
                  hidden={
                    appliedFilters.some(
                      (af) =>
                        af.filterGroup ===
                        filterObject[`${translations.k_tipology}`][0]
                          .filterGroup
                    )
                      ? false
                      : true
                  }
                >
                  <div>
                    <ul>
                      {filterObject[`${filterGroup}`].map((filterCategory) => (
                        <li>
                          <label class="rt-filters__option">
                            <input
                              type="checkbox"
                              onclick={(e: Event) => {
                                e.stopPropagation();
                                const currentInput =
                                  e.currentTarget as HTMLInputElement;
                                if (currentInput.checked) {
                                  // adding the filter to the appliedFilters state and rerendering the UI
                                  appliedFilters.push(filterCategory);
                                } else {
                                  // remove the filter from the appliedFilters state and rerendering the UI
                                  appliedFilters = appliedFilters.filter(
                                    (af) =>
                                      af.filterParam !==
                                      filterCategory.filterParam
                                  );
                                }
                                filterStores();
                                updateSideBarContent(getSideBarContent());
                              }}
                              checked={appliedFilters.some(
                                (af) =>
                                  af.filterParam === filterCategory.filterParam
                              )}
                            />
                            <span>{filterCategory.name}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          ))}
          {/* <!-- TODO: brand filter --> */}
        </ul>
      </div>
      <footer class="rt-filters__footer">
        <button
          onclick={() => {
            toggleSidebar(false);
          }}
          class="rt-filters__show-results | rt-btn rt-btn--primary"
        >
          {translations.k_show_stores}
        </button>
      </footer>
    </div>
  );
}

let i = setInterval(() => {
  try {
    if (!google || !retailtune || !sendGa4DL)
      throw new Error("Inexisting required objects to load this page!");
    clearInterval(i);
    main();
  } catch (error) {
    console.warn(error);
  }
}, 50);
